import { useState, useEffect } from "react";
import axios from 'axios'
export default function App() {
  const [roleId, setRoleId] = useState(5)
  const [userId, setUserId] = useState(432);
  const [chatHistory, setChatHistory] = useState([])
  const [userInput, setUserInput] = useState('')
  const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  })

  console.log({

    baseURL: process.env.REACT_APP_API_URL
  });
  const loadChat = async () => {
    if (!roleId || !userId) {
      alert("Please enter role id and user id")
      return;
    }
    let res = await apiClient.post(`/get-chat`, {
      "roleId": roleId,
      "userId": userId
    })
    setChatHistory(res.data.data)
  }

  const sendMessage = async () => {
    if (!userInput) {
      alert("Please enter your message")
      return;
    }
    setUserInput('')
    let res = await apiClient.post('/ask', {
      roleId,
      userId,
      message: userInput
    })
    if (res.data.data.bot_response) {
      setChatHistory([...chatHistory, {
        user_message: userInput,
        ...res.data.data
      }])
    }
  }
  return (
    <main className="w-full min-h-screen flex flex-col px-6 py-6 mx-auto">

      <div className="flex flex-col items-start gap-4">
        <div className="flex flex-row items-center gap-2">
          <span>Role Id</span>
          <input
            onChange={(e) => setRoleId(e.target.value)}
            type="text" value={roleId} className="border-gray-400 border-2" />
        </div>
        <div className="flex flex-row items-center gap-2">
          <span>User Id</span>
          <input
            onChange={(e) => setUserId(e.target.value)}
            type="text"
            value={userId}
            className="border-gray-400 border-2" />
        </div>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={loadChat}>Start</button>
      </div>

      <h1 className="text-xl mt-10">Chat</h1>


      {/* =========== Chat ================== */}
      <>
        <div className="flex-1 p:2 sm:p-6 justify-between flex flex-col h-screen border-gray-300 border-[1px] rounded-md bg-gray-100">

          <div
            id="messages"
            className="flex flex-col space-y-4 p-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch"
          >
            {
              chatHistory?.map(chat => {
                return (
                  <>
                    {/* ==== user message ====== */}
                    <div className="chat-message">
                      <div className="flex items-end justify-end">
                        <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-1 items-end">
                          <div>
                            <span className="px-4 py-2 rounded-lg inline-block rounded-br-none bg-blue-600 text-white ">
                              {chat.user_message}
                            </span>
                          </div>
                        </div>
                        <img
                          src="https://images.unsplash.com/photo-1590031905470-a1a1feacbb0b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=144&h=144"
                          alt="My profile"
                          className="w-6 h-6 rounded-full order-2"
                        />
                      </div>
                    </div>

                    <div className="chat-message">
                      <div className="flex items-end">
                        <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-2 items-start">
                          <div>
                            <span className="px-4 py-2 flex flex-col items-start rounded-lg  rounded-bl-none bg-gray-300 text-gray-600">
                              <span>
                                {
                                  chat.bot_response.answer
                                }
                              </span>
                              {
                                chat.intent !== "None" &&
                                <span className="mt-2">
                                  <b>
                                    Intent:
                                  </b>
                                  {chat.intent}
                                </span>
                              }
                    {
                                chat.intent !== "None" &&
                                <span className="mt-2">
                                  <b>
                                    Source:
                                  </b>
                                  {chat.bot_response.source&&chat.bot_response.source}
                                  {chat.bot_response.sources&&chat.bot_response.sources}
                                </span>
                              }
                            </span>
                          </div>
                        </div>
                        <img
                          src="https://images.unsplash.com/photo-1549078642-b2ba4bda0cdb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=144&h=144"
                          alt="My profile"
                          className="w-6 h-6 rounded-full order-1"
                        />
                      </div>
                    </div>
                  </>
                )
              })
            }


          </div>
          <div className="border-t-2 border-gray-200 px-4 pt-4 mb-2 sm:mb-0">
            <div className="relative flex">
              <span className="absolute inset-y-0 flex items-center">
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-full h-12 w-12 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="h-6 w-6 text-gray-600"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"
                    />
                  </svg>
                </button>
              </span>
              <input

                onChange={(e) => setUserInput(e.target.value)}
                type="text"
                placeholder="Write your message!"
                className="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-12 bg-gray-200 rounded-md py-3"
              />
              <div className="absolute right-0 items-center inset-y-0 hidden sm:flex">
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="h-6 w-6 text-gray-600"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                    />
                  </svg>
                </button>
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="h-6 w-6 text-gray-600"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                </button>
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="h-6 w-6 text-gray-600"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
                <button
                  onClick={sendMessage}
                  type="button"
                  className="inline-flex items-center justify-center rounded-lg px-4 py-3 transition duration-500 ease-in-out text-white bg-blue-500 hover:bg-blue-400 focus:outline-none"
                >
                  <span className="font-bold">Send</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-6 w-6 ml-2 transform rotate-90"
                  >
                    <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              "\n.scrollbar-w-2::-webkit-scrollbar {\n  width: 0.25rem;\n  height: 0.25rem;\n}\n\n.scrollbar-track-blue-lighter::-webkit-scrollbar-track {\n  --bg-opacity: 1;\n  background-color: #f7fafc;\n  background-color: rgba(247, 250, 252, var(--bg-opacity));\n}\n\n.scrollbar-thumb-blue::-webkit-scrollbar-thumb {\n  --bg-opacity: 1;\n  background-color: #edf2f7;\n  background-color: rgba(237, 242, 247, var(--bg-opacity));\n}\n\n.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {\n  border-radius: 0.25rem;\n}\n"
          }}
        />
      </>
      {/* <div className="flex flex-col mt-10">
        <h1> Ask</h1>
        <div className="flex flex-row items-center gap-3">
          <input
            onChange={(e) => setUserInput(e.target.value)}
            type="text" value={userInput} className="border-gray-400 border-2" />
          <button onClick={sendMessage} className=" w-fit">Send</button>
        </div>
      </div> */}
    </main >
  );
}